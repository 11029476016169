
import Vue from "vue";
import { mapActions } from "vuex";
import { CustomAxiosError, inputValidate } from "@/types/types";

type RegistrationUserType = {
  event_id: string;
  phone: string;
  email: string;
  name: string;
  surname: string;
  company?: string;
  personal_data_processing: boolean;
};

export default Vue.extend({
  name: "RegistrationForEvent",
  data() {
    return {
      agreement: false,
      registerRequestSending: false,
      phone: "",
      email: "",
      name: "",
      surname: "",
      company: "",
      inputErrors: {
        phone: "",
        email: "",
        surname: "",
        name: "",
        company: "",
        common: "",
      },
    };
  },
  props: {
    eventId: {
      type: String,
    },
    companyIsRequired: {
      type: Boolean,
    },
  },
  computed: {
    formInputs(): inputValidate {
      return this.$refs.formInputs as inputValidate;
    },
  },
  methods: {
    ...mapActions(["eventRegistration"]),
    register() {
      const valid = this.formInputs.validate();
      if (valid) {
        const user: RegistrationUserType = {
          event_id: this.eventId,
          phone: this.phone,
          email: this.email,
          name: this.name,
          surname: this.surname,
          personal_data_processing: this.agreement,
        };
        if (this.companyIsRequired) {
          user["company"] = this.company;
        }
        this.registerRequestSending = true;
        this.eventRegistration(user)
          .then(() => {
            this.openAlert("Регистрация прошла успешно");
            this.$emit("register");
          })
          .catch((error: CustomAxiosError) => {
            if (
              error?.response?.status === 400 &&
              error?.response?.data.name === "Errors of validation"
            ) {
              const data = error?.response?.data;
              if (data?.message) {
                this.inputErrors.common = error.response.data.message;
              }
              if (Object.keys(data.fail).length) {
                Object.keys(data.fail).map((el) => {
                  (this.inputErrors as { [key: string]: string })[el] =
                    data.fail[el][0];
                });
              }
            }
          })
          .finally(() => {
            this.registerRequestSending = false;
          });
      }
    },
  },
});
