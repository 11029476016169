var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"title",attrs:{"href":`#${_vm.href}`}},[_c('div',{staticClass:"dash",class:{
      dashWhite: _vm.white,
    }}),_c('div',{staticClass:"text24 bold",class:{
      'title-whiteColor': _vm.white,
    }},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('div',{staticClass:"dash",class:{
      dashWhite: _vm.white,
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }