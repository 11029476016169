
import Vue from "vue";
import { mapState } from "vuex";
import { EditableField } from "@/store/types/stateTypes";

type ModalDataType = {
  editableField: EditableField;
  fieldRegistration: string;
};

type HeaderType = {
  value: string;
  text: string;
};

export default Vue.extend({
  name: "ListParticipantCustom",
  data() {
    return {
      search: "",
      modalListRegistered: false,
      usersLoading: false,
    };
  },
  computed: {
    ...mapState(["usersInfo", "modules", "window"]),
    title(): string {
      return this.modules.moduleParticipantListCustom.title;
    },
    backgroundImage(): string {
      return this.modules.moduleParticipantListCustom.backgroundImage;
    },
    modalData(): ModalDataType {
      return this.modules.moduleParticipantListCustom.modal;
    },
    participantList(): { count: string; users: EditableField[] } {
      return this.modules.moduleParticipantListCustom.list;
    },
    headers(): HeaderType[] {
      let headers = [] as HeaderType[];
      Object.entries(
        this.modules.moduleParticipantListCustom.modal.editableField
      ).map((el) => {
        headers.push({
          value: el[0],
          text: el[1] as string,
        });
      });
      return headers;
    },
    users(): EditableField[] {
      return this.modules.moduleParticipantListCustom.list.users.map(
        (el: EditableField) => {
          return {
            field_one: this.preparedText(el.field_one ?? ""),
            field_two: this.preparedText(el.field_two ?? ""),
            field_three: this.preparedText(el.field_three ?? ""),
            field_four: this.preparedText(el.field_four ?? ""),
          };
        }
      );
    },
  },
  methods: {
    preparedText(text: string): string {
      const pattern = /((?:https?:|\bwww)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})/;
      if (pattern.test(text)) {
        if (/^https?:/.test(text)) {
          return `<a target="_blank" href=${text}>${text}</a>`;
        } else {
          const linkWithHttp = `http://${text}`;
          return `<a target="_blank" href=${linkWithHttp}>${text}</a>`;
        }
      } else return text;
    },
  },
});
