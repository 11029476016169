var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-wrapper team"},[_c('Title',{attrs:{"text":_vm.title,"href":"teamMember"}}),_c('div',{staticClass:"team__box container-wrapper"},[_vm._l((_vm.teamList),function(category,i){return _c('div',{key:i,class:[
        `team__box-category${i + 1}`,
        {
          'team__box-category3-oneLine':
            i === 2 && Object.keys(category)?.length <= 4,
        },
      ]},[_vm._l((category),function(item,y){return _c('div',{key:y,style:({
          gridArea: `${i + 1}item${y + 1}`,
        }),on:{"click":function($event){return _vm.showModal(item)}}},[_c('TeamMember',{attrs:{"photo":item.photo,"category":item.category,"name":item.name,"job_title":item.job_title}})],1)}),(
          (i === 1 && Object.keys(category)?.length >= 3) ||
          (i === 2 && Object.keys(category)?.length >= 5) ||
          i === 0
        )?_c('div',{class:[`team__box-category${i + 1}-line line`]}):_vm._e()],2)}),_c('div',{staticClass:"team__box-line line"})],2),_c('DefaultModal',{attrs:{"modal":_vm.modal.show,"height":"auto","width":"770"},on:{"close":function($event){_vm.modal.show = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.modal.item.name))]},proxy:true},{key:"additional",fn:function(){return [_c('div',{staticClass:"team__modal"},[_c('div',{staticClass:"team__modal-leftBlock"},[(_vm.modal.item?.photo)?_c('img',{staticClass:"team__modal-leftBlock-img",attrs:{"src":_vm.modal.item.photo,"alt":""}}):_vm._e(),(_vm.modal.item.phone?.length || _vm.modal.item.email?.length)?_c('div',{staticClass:"mt-6"},[(_vm.modal.item.phone?.length)?_c('div',{staticClass:"team__modal-leftBlock-contact"},[_c('span',{staticClass:"team__modal-leftBlock-contact-title bold"},[_vm._v("Телефон:")]),_c('span',[_vm._v(_vm._s(_vm.modal.item.phone))])]):_vm._e(),(_vm.modal.item.email?.length)?_c('div',{staticClass:"team__modal-leftBlock-contact"},[_c('span',{staticClass:"team__modal-leftBlock-contact-title bold"},[_vm._v("Email:")]),_c('span',[_vm._v(_vm._s(_vm.modal.item.email))])]):_vm._e(),(_vm.modal.item.video)?_c('div',{staticClass:"team__modal-leftBlock-video pointer"},[_c('VideoLink',{attrs:{"is-slot":"","link":_vm.modal.item.video,"id":"test"}},[_c('span',[_vm._v("Посмотреть обращение")])])],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"team__modal-job"},[_vm._v(" "+_vm._s(_vm.modal.item.job_title)+" ")]),_c('div',{staticClass:"team__modal-description",attrs:{"id":"description"},domProps:{"innerHTML":_vm._s(
            _vm.showAllDescription || _vm.modal.item.description?.length <= 250
              ? _vm.modal.item.description
              : `${_vm.modal.item.description?.slice(0, 220)}...`
          )}}),(_vm.modal.item.description?.length > 250)?_c('button',{staticClass:"text-decoration-underline mt-3",on:{"click":function($event){_vm.showAllDescription = !_vm.showAllDescription}}},[_vm._v(" "+_vm._s(_vm.showAllDescription === true ? "Скрыть" : "Читать далее")+" ")]):_vm._e()])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }