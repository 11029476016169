var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"eventCalendar"},[_c('div',{staticClass:"container-wrapper"},[_c('Title',{attrs:{"text":_vm.title,"href":"calendar"}}),_c('div',{staticClass:"eventCalendar__calendar-box"},[_c('div',{staticClass:"eventCalendar__calendar-box__calendar"},[_c('Calendar',{attrs:{"daysWithEvents":_vm.daysWithEvents,"active":_vm.activeDay},on:{"updateCurrentDate":function($event){return _vm.updateCurrentDate($event)}}})],1),_c('div',{staticClass:"eventCalendar__right"},[_c('div',{staticClass:"eventCalendar__right__header"},[_c('div',{staticClass:"eventCalendar__right__header__title"},[_vm._v(_vm._s(_vm.weekday))]),_c('div',{staticClass:"eventCalendar__right__header__date"},[_vm._v(" "+_vm._s(_vm.date)+" ")])]),_c('div',_vm._l((_vm.daysEvents),function(event,i){return _c('div',{key:i},[_c('div',{style:({
                background: i % 2 === 0 ? '#373F54' : '',
              })},[_c('a',{staticClass:"eventCalendar__right__event pointer",attrs:{"href":`?eventId=${event.id}#calendar`},on:{"click":function($event){return _vm.changeCurrentEvent($event, event)}}},[_c('span',{staticClass:"eventCalendar__right__event-text-box"},[_c('span',{staticClass:"eventCalendar__right__event-circle"}),_c('span',{staticClass:"eventCalendar__right__event-text"},[_vm._v(" "+_vm._s(event.title)+" ")])]),_c('span',{staticClass:"eventCalendar__right__event-time"},[_vm._v(" "+_vm._s(`${event.startTime} - ${event.endTime}`)+" ")])])])])}),0)])]),_c('div',{staticClass:"eventCalendar__event",attrs:{"id":"event"}},[_c('div',{staticClass:"eventCalendar__event-title text24 bold"},[_vm._v(" "+_vm._s(_vm.currentEvent.title)+" ")]),_c('div',{staticClass:"eventCalendar__event-time"},[_vm._v(" "+_vm._s(`${_vm.currentEvent.date} ${_vm.currentEvent.startTime} - ${_vm.currentEvent.endTime}`)+" "),(_vm.currentEventCompleted)?_c('span',{staticClass:"red--text"},[_vm._v(" Мероприятие завершено ")]):_vm._e()]),(!_vm.currentEventCompleted && _vm.currentEvent.enableRegistration)?_c('div',{staticClass:"eventCalendar__event-btn beforeText"},[_c('button',{on:{"click":_vm.goToRegistration}},[_vm._v(_vm._s(_vm.btnText))])]):_vm._e(),_c('div',{staticClass:"eventCalendar__event-description",class:{
          oneColumn: !_vm.currentEvent.video && !_vm.currentEvent?.image,
        }},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.currentEvent.text)}}),(_vm.currentEvent.video)?_c('div',{staticClass:"eventCalendar__event-description-img video"},[_c('VideoLink',{attrs:{"link":_vm.currentEvent.video,"id":_vm.currentEvent.id,"isPoster":!_vm.currentEvent.image,"poster":_vm.currentEvent.image}})],1):(_vm.currentEvent?.image)?_c('div',[_c('img',{staticClass:"eventCalendar__event-description-img",attrs:{"src":_vm.currentEvent?.image,"alt":_vm.currentEvent.title}})]):_vm._e()]),(
          !_vm.showRegistration &&
          !_vm.currentEventCompleted &&
          _vm.currentEvent.enableRegistration
        )?_c('div',{staticClass:"eventCalendar__event-btn"},[_c('MainButton',{attrs:{"colorRed":"","slim":""},on:{"click":function($event){_vm.showRegistration = true}}},[_vm._v(_vm._s(_vm.btnText))])],1):(!_vm.currentEventCompleted && _vm.currentEvent.enableRegistration)?_c('div',{attrs:{"id":"eventRegistration"}},[_c('RegistrationForEvent',{attrs:{"eventId":_vm.currentEvent.id,"company-is-required":_vm.currentEvent.companyIsRequired},on:{"register":function($event){_vm.showRegistration = false}}})],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }